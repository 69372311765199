import { Component, OnInit } from '@angular/core';
import { DivisionsService } from '../services/divisions/divisions.service';
import { ClassificationsService } from '../services/classifications/classifications.service';
import { ResponsibilitiesService } from '../services/responsibilities/responsibilities.service';
import  { LocationsService } from '../services/locations/locations.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
    divisions:any;
    divisions_advanced:any;
    responsibilities:any;
    locations:any;
    base_division:any = null;
    advanced_division:any = null;
    locations_responsibilities:any=[];
    response:any;
    checkedArr:any=[];
    classifications:any;
    classification_id = 0;
  constructor(private ds:DivisionsService, private cs:ClassificationsService, private rs: ResponsibilitiesService, private ls:LocationsService) {
      this.ds.get().subscribe((result)=>{
          this.divisions = result;
          this.divisions_advanced = result;
      },(error)=>{
          this.rs.checkError(error);
      });
      this.cs.get().subscribe((result)=>{
        this.classifications = result;
    },(error)=>{
        this.rs.checkError(error);
    });
  }

  ngOnInit() {
  }
  getResponsibilities(divisionId){
    this.classification_id=0;
    this.responsibilities = [];
    if(divisionId === 'null'){
      return;
    }
      if(!this.advanced_division){
          this.getLocations(divisionId);
      }
      this.rs.get(divisionId).subscribe((result)=>{
        this.responsibilities = result;
      },(error)=>{
          this.rs.checkError(error);
      });
  }
  getResponsibilitiesReset(divisionId){
      this.advanced_division=null;
      this.getResponsibilities(divisionId)
  }
  getLocations(divisionId){
    this.locations=[];
    if(divisionId === 'null'){
      return;
    }
    this.locations_responsibilities=[];
      this.ls.get(divisionId).subscribe((result)=>{
          this.locations = result;
      },(error)=>{
          this.rs.checkError(error);
      });
      this.ls.locations_responsibilities(divisionId).subscribe((result)=>{
        this.response  = result;
        this.response.forEach((row) => {
            if(row.active){
                this.locations_responsibilities[row.responsibility+"_"+row.divsion.location]="yes";
            }
            else{
                this.locations_responsibilities[row.responsibility+"_"+row.divsion.location]="no";
            }
            this.locations_responsibilities[row.responsibility+"_"+row.divsion.location+"_id"]=row.id;
        })
    },(error)=>{
        this.rs.checkError(error);
    });
  }
  checkResposibility(responsibilityId,locationId){
    if(this.locations_responsibilities[responsibilityId+"_"+locationId] == "yes")
    {
        return "checked";
    }
    return "";
  }
  isSelected(event,responsibility_id,location_division_id,location_id){
    let division_id = 0;
    if(this.advanced_division){
      division_id = this.advanced_division;
    } else {
      division_id = this.base_division;
    }
      if(event.target.checked){
        var data = {division_location:location_division_id,responsibility:responsibility_id,active:true};
      }
      else{
        var data = {division_location:location_division_id,responsibility:responsibility_id,active:false};
      }
      //console.log(this.locations_responsibilities);
    if(this.locations_responsibilities[responsibility_id+"_"+location_id]){
        this.ls.update(this.locations_responsibilities[responsibility_id+"_"+location_id+"_id"],division_id,data).subscribe((result)=>{
            //alert("saved");
            this.response  = result;
            if(this.response.active){
                this.locations_responsibilities[this.response.responsibility+"_"+this.response.divsion.location]="yes";
            }
            else{
                this.locations_responsibilities[this.response.responsibility+"_"+this.response.divsion.location]="no";
            }
            this.locations_responsibilities[this.response.responsibility+"_"+this.response.divsion.location+"_id"]=this.response.id;
            });
    }
    else{
        this.ls.post(data).subscribe((result)=>{
            //alert("saved");
            this.response  = result;
            if(this.response.active){
                this.locations_responsibilities[this.response.responsibility+"_"+this.response.divsion.location]="yes";
            }
            else{
                this.locations_responsibilities[this.response.responsibility+"_"+this.response.divsion.location]="no";
            }
            this.locations_responsibilities[this.response.responsibility+"_"+this.response.divsion.location+"_id"]=this.response.id;
            });
    }
  }
  check_classification(classification) {
    if (this.classification_id !== classification) {
      this.classification_id = classification;
      return true;
    } else {
      return false;
    }
  }

}
