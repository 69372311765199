import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth/auth.service';

import { environment } from '../../environments/environment';

const API_URL = environment.apiBase;
const client_id = environment.client_id;
const client_secret = environment.client_secret;
const redirect_uri = environment.redirect_uri;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private auth:AuthService) {
    window.location.href = API_URL+"/oauth/authorize?client_id="+client_id+"&response_type=code&redirect_uri="+redirect_uri;
   }

  ngOnInit() {
  }
  authorizeclient(){
      this.auth.authorizeuser();
  }
}
