import { Injectable } from '@angular/core';
import { HttpClientCust } from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class DivisionsService {

    constructor(private http:HttpClientCust) { }
    get(){
        return this.http.get("/organization/divisions/");
    }

    post(data){
        return this.http.post("/organization/divisions/",data)
      }
    put(id,data){
        return this.http.put("/organization/divisions/"+id+"/",data);
      }
    delete(id){
      return this.http.delete("/organization/divisions/"+id+"/");
    }

    checkError(error){
        return this.http.checkError(error);
    }
}
