import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../services/employee/employee.service';
import { ApplicationSettingsService } from '../services/application-settings/application-settings.service';

@Component({
  selector: 'app-my-responsibility',
  templateUrl: './my-responsibility.component.html',
  styleUrls: ['./my-responsibility.component.css']
})
export class MyResponsibilityComponent implements OnInit {
  emp_responsibilities:any;
  classification_id:number;
  application_settting:any={};
  constructor(private es:EmployeeService,private ass:ApplicationSettingsService) { 
    this.ass.get().subscribe((result)=>{
      if(result[0]){
        this.application_settting = result[0];
      }else{
        this.application_settting.is_role_based_responsibility_ass = 0;
      this.application_settting.id =""
      }
      
    },(error)=>{
      this.ass.checkError(error);
    });

    this.es.getCurrentUserResponsibility().subscribe((result)=>{
      this.emp_responsibilities=result;
    },(error)=>{
      this.es.checkError(error);
    });
  }

  ngOnInit() {
  }

  check_classification(classification) {
    if (this.classification_id !== classification) {
      this.classification_id = classification;
      return true;
    } else {
      return false;
    }
  }

}
