import { Component, OnInit } from '@angular/core';
import { DivisionsService } from '../services/divisions/divisions.service';
declare var $: any;
@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css']
})
export class DivisionComponent implements OnInit {

  divisions:any;
  formDivision:any = {};
  formErrors: any = {};
  constructor(private ds:DivisionsService) { 
    this.getDivisions();
  }

  ngOnInit() {
  }
  getDivisions(){
    this.ds.get().subscribe((result)=>{
      this.divisions = result;
    },(error)=>{
      this.ds.checkError(error);
    });
  }

  saveScope() {
    this.formErrors = {};
    if (this.formDivision.id) {
      this.ds.put(this.formDivision.id, this.formDivision).subscribe((result) => {
        this.reset_from();
        $('#division_modal').modal('hide');
        this.getDivisions();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ds.checkError(error);
      });
    } else {
      this.ds.post(this.formDivision).subscribe((result) => {
        this.reset_from();
        $('#division_modal').modal('hide');
        this.getDivisions();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ds.checkError(error);
      });
    }
  }

  reset_from(){
    this.formDivision= {};
  }
  openedit(division) {
    this.formDivision = division;
  }

  conformed_delete(scope) {
    if (confirm("Are you sure to delete " + scope.name + "?")) {
      this.ds.delete(scope.id).subscribe((result) => {
        this.getDivisions();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ds.checkError(error);
      });
    }
  }

}
