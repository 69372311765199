import {
  Injectable
} from '@angular/core';
import {
  HttpClientCust
} from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class ClassificationsService {

  constructor(private http: HttpClientCust) {}
  get() {
    return this.http.get("/r-and-r/classifications/");
  }
  new(data) {
    return this.http.post("/r-and-r/classifications/", data);
  }
  checkError(error) {
    return this.http.checkError(error);
  }
}
