import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { DndModule } from 'ng2-dnd';
import { FormsModule } from '@angular/forms';
import {Nl2BrPipeModule} from 'nl2br-pipe';


import {AuthService} from './services/auth/auth.service';
import { HttpClientCust } from './services/http/http-client-cust';
import { DivisionsService } from './services/divisions/divisions.service';
import { ClassificationsService } from './services/classifications/classifications.service';
import { DivisionClassificationsService } from './services/division-classifications/division-classifications.service';
import { ResponsibilitiesService } from './services/responsibilities/responsibilities.service';
import { LocationsService } from './services/locations/locations.service';
import { UserService } from './services/user/user.service';
import { AuditService } from './services/audit/audit.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './/app-routing.module';
import { LoginComponent } from './login/login.component';
import { CallbackComponent } from './callback/callback.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { TestComponent } from './test/test.component';
import { ResponsibilitiesComponent } from './responsibilities/responsibilities.component';
import { ClassificationsComponent } from './classifications/classifications.component';
import { LocationsComponent } from './locations/locations.component';
import { EmployeeComponent } from './employee/employee.component';
import { SearchComponent } from './search/search.component';
import { OrgScopeComponent } from './org-scope/org-scope.component';
import { DivisionComponent } from './division/division.component';
import { ApplicationSettingsComponent } from './application-settings/application-settings.component';
import { AuditComponent } from './audit/audit.component';
import { DivisionClassificationsComponent } from './division-classifications/division-classifications.component';
import { MyResponsibilityComponent } from './my-responsibility/my-responsibility.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CorrelationComponent } from './correlation/correlation.component';
import { NvD3Module } from 'ng2-nvd3';

// d3 and nvd3 should be included somewhere
import 'd3';
import 'nvd3';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    CallbackComponent,
    ForbiddenComponent,
    TestComponent,
    ResponsibilitiesComponent,
    ClassificationsComponent,
    LocationsComponent,
    EmployeeComponent,
    SearchComponent,
    OrgScopeComponent,
    DivisionComponent,
    ApplicationSettingsComponent,
    AuditComponent,
    DivisionClassificationsComponent,
    MyResponsibilityComponent,
    CorrelationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    Nl2BrPipeModule,
    NgHttpLoaderModule,
    NvD3Module,
    DndModule.forRoot()
  ],
  providers: [AuthService,
    HttpClientCust,
    ResponsibilitiesService,
    ClassificationsService,
    DivisionsService,
    LocationsService,
    UserService,
    AuditService,
    DivisionClassificationsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
