import { Component, OnInit } from '@angular/core';
import { DivisionClassificationsService } from '../services/division-classifications/division-classifications.service';
import { DivisionsService } from '../services/divisions/divisions.service';
declare var $: any;
@Component({
  selector: 'app-division-classifications',
  templateUrl: './division-classifications.component.html',
  styleUrls: ['./division-classifications.component.css']
})
export class DivisionClassificationsComponent implements OnInit {
  core_responsibilities: any;
  core_responsibilitie: any = {};
  divisions:any=[];
  formDivisionFunction:any = {};
  formErrors: any = {};
  constructor(private dcs:DivisionClassificationsService,private ds: DivisionsService) {
    this.ds.get().subscribe((result) => {
      this.divisions = result;
    }, (error) => {
      this.ds.checkError(error);
    });
   }

  ngOnInit() {
    this.getCoreResponsibility();
  }
  getCoreResponsibility(){
    this.dcs.get().subscribe((result)=>{
      this.core_responsibilities = result;
  });
}
  saveCoreResponsibility(){
    if(this.formDivisionFunction.id){
      this.dcs.put(this.formDivisionFunction.id, this.formDivisionFunction).subscribe((result) => {
        
        $('#core_res_add').modal('hide');
        this.getCoreResponsibility();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ds.checkError(error);
      });
    }else{
      this.dcs.new(this.formDivisionFunction).subscribe((data) => {
        $('#core_res_add').modal('hide');
        this.getCoreResponsibility()
      },(error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ds.checkError(error);
      });
    }
    this.reset_from();
  }

  reset_from(){
    this.formDivisionFunction= {};
  }
  openedit(division_function) {
    this.formDivisionFunction = division_function;
  }

  conformed_delete(scope) {
    if (confirm("Are you sure to delete " + scope.name + "?")) {
      this.dcs.delete(scope.id).subscribe((result) => {
        this.getCoreResponsibility();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.dcs.checkError(error);
      });
    }
  }

}
