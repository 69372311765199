import { Injectable } from '@angular/core';
import { HttpClientCust } from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http:HttpClientCust) { }
  getRole(){
    return this.http.get("/r-and-r/role/")
  }
  getEmployeeResponsibilityRole(locationDivisoinId){
    return this.http.get("/r-and-r/employee-responsibility/?division_location="+locationDivisoinId)
  }
  createEmployeeResponsibilityRole(data){
    return this.http.post("/r-and-r/employee-responsibility/",data)
  }
  updateEmployeeResponsibilityRole(id,locationDivisoinId,data){
    return this.http.put("/r-and-r/employee-responsibility/"+id+"/?division_location="+locationDivisoinId,data);
  }
  getResponsibilities(division_id){
    return this.http.get("/r-and-r/responsibilities/?division="+division_id)
  }
  
  checkError(error){
    return this.http.checkError(error);
  }
}
