import { Component, OnInit } from '@angular/core';
import { DivisionsService } from '../services/divisions/divisions.service';
import { ClassificationsService } from '../services/classifications/classifications.service';
import { ResponsibilitiesService } from '../services/responsibilities/responsibilities.service';
declare var $: any;
@Component({
  selector: 'app-responsibilities',
  templateUrl: './responsibilities.component.html',
  styleUrls: ['./responsibilities.component.css']
})
export class ResponsibilitiesComponent implements OnInit {
  divisions: any;
  classifications: any;
  formErrors: any = {};
  formSuccess: boolean = false;
  responsibilities: any;
  formResponsibilities: any = {};
  classification_id = 0;
  division_id = 0;
  responsibility_description = '';
  division_classification : any;
  constructor(private ds: DivisionsService, private cs: ClassificationsService, private rs:  ResponsibilitiesService) {}

  ngOnInit() {
    this.getResposiblities();
    this.ds.get().subscribe((result) => {
      this.divisions = result;
    }, (error) => {
      this.rs.checkError(error);
    });
    this.cs.get().subscribe((result) => {
      this.classifications = result;
    }, (error) => {
      this.rs.checkError(error);
    });
  }
  getResposiblities() {
    this.rs.get().subscribe((result) => {
      this.responsibilities = result;
    }, (error) => {
      this.rs.checkError(error);
    });
  }
  saveResponsibility() {
    this.formErrors = {};
    if(this.formResponsibilities.division_function === 'null'){
      this.formResponsibilities.division_function = "";
    }
    if (this.formResponsibilities.id) {
      this.rs.put(this.formResponsibilities.id, this.formResponsibilities).subscribe((result) => {
        $('#responsibility_modal').modal('hide');
        this.formSuccess = true;
        this.getResposiblities();
      }, (error) => {
        this.formSuccess = false;
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.rs.checkError(error);
      });
    } else {
      this.rs.post(this.formResponsibilities).subscribe((result) => {
        $('#responsibility_modal').modal('hide');
        this.formSuccess = true;
        this.reset_from();
        this.getResposiblities();
      }, (error) => {
        this.formSuccess = false;
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.rs.checkError(error);
      });
    }
  }
  openedit(responsibility) {

    this.formSuccess = false;
    this.formErrors  = [];
    this.reset_from();
    this.formResponsibilities = responsibility;
    if(responsibility.division_function){
      this.geClassifications(responsibility.division);
    }
    this.formResponsibilities.is_disable=true;
  }
  reset_from() {
    this.formResponsibilities = {
      id: null,
      name: "",
      division: null,
      classification: null,
      division_function: "null",
      is_disable:false
    };
    this.formSuccess = false;
    this.formErrors  = [];
  }
  conformed_delete(responsibility) {
    //alert("d");
    if (confirm("Are you sure to delete " + responsibility.name + "?")) {
      this.rs.delete(responsibility.id).subscribe((result) => {
        this.getResposiblities();
      }, (error) => {
        this.formSuccess = false;
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.rs.checkError(error);
      });
    }
  }
  check_classification(classification) {
    if (this.classification_id !== classification) {
      this.classification_id = classification;
      return true;
    } else {
      return false;
    }
  }
  check_division(division) {
    if (this.division_id !== division) {
      this.division_id = division;
      this.classification_id = 0;
      return true;
    } else {
      return false;
    }
  }
  check_responsibility(classification) {
    if (this.classification_id !== classification) {
      return true;
    } else {
      return false;
    }
  }
  check_div_td_boder(division){
    if (this.division_id !== division) {
      //return false;
      //no border always
      return true;
    } else {
      return true;
    }
  }
  check_td_boder(classification){
    if (this.classification_id !== classification) {
      //return false;
      //no border always
      return true;
    } else {
      return true;
    }
  }
  updateModal(description){
  this.responsibility_description = description;
  }
  geClassifications(division){
    this.rs.getClassification(division).subscribe((result) => {
      this.division_classification = result;
    }, (error) => {
      this.rs.checkError(error);
    });
  }
}
