import {
  Injectable
} from '@angular/core';
import {
  HttpClientCust
} from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private http: HttpClientCust) {}
  get(module) {
    if(module === 'all'){
      return this.http.get("/organization/log-entry/");
    }
    return this.http.get("/organization/log-entry/?model_name="+module);
  }
  getAllModels(){
    return this.http.get("/organization/list-model");
  }
  checkError(error) {
    return this.http.checkError(error);
  }
}
