import { Component, OnInit } from '@angular/core';
import { OrgScopeService } from '../services/org-scope/org-scope.service';
declare var $: any;

@Component({
  selector: 'app-org-scope',
  templateUrl: './org-scope.component.html',
  styleUrls: ['./org-scope.component.css']
})
export class OrgScopeComponent implements OnInit {
  org_scope:any;
  formScope:any = {};
  formErrors: any = {};
  constructor(private ss:OrgScopeService) {
    this.getScope()
  }

  ngOnInit() {
  }
  getScope(){
    this.ss.get().subscribe((result)=>{
      this.org_scope = result;
    },(error)=>{
      this.ss.checkError(error);
    });
  }
  reset_from(){
    this.formScope= {};
  }
  saveScope() {
    this.formErrors = {};
    if (this.formScope.id) {
      this.ss.put(this.formScope.id, this.formScope).subscribe((result) => {
        this.reset_from();
        $('#scope_modal').modal('hide');
        this.getScope();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ss.checkError(error);
      });
    } else {
      this.ss.post(this.formScope).subscribe((result) => {
        this.reset_from();
        $('#scope_modal').modal('hide');
        this.getScope();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ss.checkError(error);
      });
    }
  }
  openedit(form_scope) {
    this.formScope = form_scope;
  }
  conformed_delete(scope) {
    if (confirm("Are you sure to delete " + scope.name + "?")) {
      this.ss.delete(scope.id).subscribe((result) => {
        this.getScope();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ss.checkError(error);
      });
    }
  }

}
