import { Component, OnInit } from '@angular/core';
import { ApplicationSettingsService } from '../services/application-settings/application-settings.service';
declare var $: any;
@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.css']
})
export class ApplicationSettingsComponent implements OnInit {
  application_settting:any={};
  formErrors: any = {};
  formSuccess:boolean=false;
  constructor(private ass:ApplicationSettingsService) { 
    this.application_settting.is_role_based_responsibility_ass = 0;
    this.application_settting.id =""
    this.getSetting();
  }
  getSetting(){
    this.ass.get().subscribe((result)=>{
      if(result[0]){
        this.application_settting = result[0];
      }else{
        this.application_settting.is_role_based_responsibility_ass = 0;
      this.application_settting.id =""
      }
      
    },(error)=>{
      this.ass.checkError(error);
    });
  }

  saveSetting() {
    this.formErrors = {};
    if (this.application_settting.id) {
      this.ass.put(this.application_settting.id, this.application_settting).subscribe((result) => {
        $('#division_modal').modal('hide');
        this.formSuccess=true;
        this.getSetting();
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ass.checkError(error);
      });
    } else {
      this.ass.post(this.application_settting).subscribe((result) => {
        $('#division_modal').modal('hide');
        this.formSuccess=true;
      }, (error) => {
        if (error.status === 400) {
          this.formErrors = error.error;
        }
        this.ass.checkError(error);
      });
    }
  }

  ngOnInit() {
  }

}
