import {
  Injectable
} from '@angular/core';
import {
  HttpClientCust
} from '../http/http-client-cust';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClientCust) {}
  info() {
    return this.http.get("/user/userinfo/");
  }
  checkError(error) {
    return this.http.checkError(error);
  }
}
