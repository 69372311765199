import { Injectable } from '@angular/core';
import { HttpClientCust } from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http:HttpClientCust) { }
    get(division_locationId){
        return this.http.get("/employees/job-location/?division_location="+division_locationId);
    }
    
    post(data){
      return this.http.post("/r-and-r/locations_responsibilities/",data);
  }
  getAllEmployee(){
    return this.http.get("/employees/users/");
  }
  getResponsibilitiesByEmployee(emp_id){
    return this.http.get("/r-and-r/employee-responsibilities/?employee="+emp_id);
  }
  getEmployeeAndRoles(division_id,responsibility_id){
    return this.http.get("/r-and-r/employee-responsibility/?responsibility_id="+responsibility_id+"&division="+division_id)
  }
  getCurrentUserResponsibility(){
    return this.http.get("/r-and-r/employee-responsibility/?get_currentuser="+true);
  }
  checkError(error){
      return this.http.checkError(error);
  }
}
