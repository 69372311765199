import { Injectable } from '@angular/core';
import { HttpClientCust } from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class LocationsService {

    constructor(private http:HttpClientCust) { }
    get(divisionId){
        return this.http.get("/r-and-r/location_by_division/?division="+divisionId);
    }
    locations_responsibilities(divisionId){
        return this.http.get("/r-and-r/locations_responsibilities/?division="+divisionId);
    }
    responsibilities_location(locationsdivisionId,responsibility_by_active=null){
        
    return this.http.get("/r-and-r/locations_responsibilities/?division_location="+locationsdivisionId+"&responsibility_by_active="+responsibility_by_active);
    //   return this.http.get("/r-and-r/locations_responsibilities/?division_location="+locationsdivisionId);
    }
    post(data){
        return this.http.post("/r-and-r/locations_responsibilities/",data);
    }
    update(id,division_id,data){
        return this.http.put("/r-and-r/locations_responsibilities/"+id+"/?division="+division_id,data);
    }
    delete(id,divisionId){
        return this.http.delete("/r-and-r/locations_responsibilities/"+id+"/?division="+divisionId);
    }
    checkError(error){
      return this.http.checkError(error);
  }
}
