import { Component } from '@angular/core';
import {AuthService} from './services/auth/auth.service';
import {Router} from '@angular/router';
import { UserService } from './services/user/user.service';
import { environment } from './../environments/environment';

const API_URL = environment.apiBase;
const client_id = environment.client_id;
const client_secret = environment.client_secret;
const redirect_uri = environment.redirect_uri;
const site_url = environment.site_url;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  currentAuth:any;
  currentUser:any;
  user_info:any={};
  current_menu:string="";
  currentYear = (new Date()).getFullYear();
  constructor(private auth:AuthService, private router: Router,private us:UserService) {
      if(window.location.pathname != "/callback" && window.location.pathname != "/forbidden")
      {
          this.currentUser = this.auth.user();
          this.currentAuth = this.auth.token();
          if(!this.currentAuth){
              this.router.navigateByUrl('/login');
          }else{
            this.us.info().subscribe((result)=>{
                  this.user_info = result;
                  // if(!this.user_info.is_superuser || !this.user_info.is_superuser.division_head) {
                  //   this.router.navigateByUrl('/my-responsibilities');
                  // }           
              },(error)=>{
                this.us.checkError(error);
              });
          }
      }
  }
  ngDoCheck() {
    this.current_menu=this.router.url;
      this.currentUser = this.auth.user();
      this.currentAuth = this.auth.token();
  }
  
  logout(){
    this.auth.logout();
    window.location.href  = API_URL+"/accounts/logout/?next=/logout-redirect?redirect_url="+site_url;
  }
}
