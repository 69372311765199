import { Component, OnInit } from '@angular/core';
import { AuditService } from '../services/audit/audit.service';
@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {
  module: any;
  audits: any;
  models:any = [];
  constructor(private as: AuditService) {
    this.getAudit('all');
    this.as.getAllModels().subscribe((result) => {
      this.models = result;
    },(error)=>{
      this.as.checkError(error);
    });
   }

  ngOnInit() {
  }
  getAudit(module){
    this.as.get(module).subscribe((result) => {
      this.audits = result;
    },(error)=>{
      this.as.checkError(error);
    });
  }
  checkChanges(changes){
    var htmlStr = '';
    Object.keys(changes).forEach(element => {
      if(element !== 'created_at' && element !== 'modified_at' && element !== 'created_by' && element !== 'modified_by' && element !== 'id'){
        htmlStr += '<h5 class="text-primary">'+element+'</h5>';
        if(changes[element][0] !== 'None')
        {
          htmlStr += '<p class="text-danger"><s>'+changes[element][0]+'</s></p>';
        }
        htmlStr += '<p class="text-success">'+changes[element][1]+'</p>';
      }
    });
    return htmlStr;
  }
  checkAction(action){
    if(action === 0){
      return "New";
    }else if(action === 1){
      return "Update";
    }else{
      return "Delete";
    }
  }
}
