import { Component, OnInit } from '@angular/core';
import { ClassificationsModule } from './classifications.module';
import { ClassificationsService } from '../services/classifications/classifications.service';
declare var $: any;
@Component({
  selector: 'app-classifications',
  templateUrl: './classifications.component.html',
  styleUrls: ['./classifications.component.css']
})
export class ClassificationsComponent implements OnInit {
  core_responsibilities:ClassificationsModule[];
  core_responsibilitie:any={};
  divisions:any=[];
  constructor(private cs:ClassificationsService) { }

  ngOnInit() {
    this.getCoreResponsibility();
  }
  getCoreResponsibility(){
    this.cs.get().subscribe((result: ClassificationsModule[])=>{
      this.core_responsibilities = result;
  });
}
  saveCoreResponsibility(){
    this.cs.new(this.core_responsibilitie).subscribe((data: ClassificationsModule[]) => {
      $('#core_res_add').modal('hide');
      this.getCoreResponsibility()
    });
  }

}
