import {
  Component,
  OnInit
} from '@angular/core';
import {
  DivisionsService
} from '../services/divisions/divisions.service';
import {
  ResponsibilitiesService
} from '../services/responsibilities/responsibilities.service';
import { OrgScopeService } from '../services/org-scope/org-scope.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentdivision: any;
  divisions: any;
  dataview = 'cpsv';
  responsibilities: any;
  classifications = new Array();
  classification_id = 0;
  org_scope:any={};
  constructor(private ds: DivisionsService, private rs: ResponsibilitiesService,private ss:OrgScopeService) {
    this.ss.get().subscribe((result)=>{
      this.org_scope=result[0];
    },(error)=>{
      this.ss.checkError(error);
    });
  }
  ngOnInit() {
    //this.reg_reponsiblity("Accounts");
    this.ds.get().subscribe((result) => {
      this.divisions = result;
      if(result[0]){
        this.getReponsiblity(result[0].id);
      }
    }, (error) => {
      this.ds.checkError(error);
    });
  }
  getReponsiblity(divisionId) {
    this.rs.get(divisionId,this.dataview).subscribe((result) => {
      this.responsibilities = result;
      if(this.dataview === 'cpsv'){
        this.cpsv();
      } else {
        this.dfv();
      }
    },(error)=>{
      this.rs.checkError(error);
    });
    this.currentdivision = divisionId;
  }
  cpsv() {
    var i=-1;
    var j=0;
    this.classification_id=0;
    this.classifications=new Array();
    this.responsibilities.forEach(element => {
      if(this.classification_id != element.classification){
          this.classification_id=element.classification;
          this.classifications[++i]=new Array();
          this.classifications[i]['name']=element.classification_data.name;
          this.classifications[i]['id']=element.classification;
          this.classifications[i]['responsibilities']=new Array();
          this.classifications[i]['responsibilities'].push({id:element.id,name:element.name,description:element.description,position:element.position});
      }
      else{
          this.classifications[i]['responsibilities'].push({id:element.id,name:element.name,description:element.description,position:element.position});
      }
    });
}
  dfv() {
      var i=-1;
      var j=0;
      this.classification_id=0;
      this.classifications=new Array();
      this.responsibilities.forEach(element => {
        if(this.classification_id != element.division_function){
            this.classification_id=element.division_function;
            this.classifications[++i]=new Array();
            if(element.function_data){
              this.classifications[i]['name']=element.function_data.name;
            }
            else{
              this.classifications[i]['name']='Unassigned';
            }
            this.classifications[i]['id']=element.division_function;
            this.classifications[i]['responsibilities']=new Array();
            this.classifications[i]['responsibilities'].push({id:element.id,name:element.name,description:element.description,position:element.function_position});
        }
        else{
            this.classifications[i]['responsibilities'].push({id:element.id,name:element.name,description:element.description,position:element.function_position});
        }
      });
  }
  itemDroped(core_id,responsibility,i) {
    //this.isChanged = true;
    var postdata= new Array();
    this.classifications.forEach(element => {
        var i=1;
        element.responsibilities.forEach(element1 => {
            postdata.push({classification:element.id,id:element1.id,position:i++});
        });
    });
    this.rs.updatePositions({data:postdata},this.dataview).subscribe((result) => {
        //this.isChanged = false;
      });
  }
  isSelected(event){
    if(event.target.checked){
      this.dataview = 'dfv';
      this.getReponsiblity(this.currentdivision);
      //this.cpsv();
    } else {
      this.dataview = 'cpsv';
      this.getReponsiblity(this.currentdivision);
      //this.dfv();
    }
  }
}
