import { Injectable } from '@angular/core';
import {Http, Headers} from '@angular/http';
import {Router} from '@angular/router';
import { environment } from '../../../environments/environment';

const API_URL = environment.apiBase;
const client_id = environment.client_id;
const client_secret = environment.client_secret;
const redirect_uri = environment.redirect_uri;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: Http, private router: Router) { }
    authorizeuser() {
      window.location.href = API_URL+"/oauth/authorize?client_id="+client_id+"&response_type=code&redirect_uri="+redirect_uri;
    }
    getAccessToken(code) {
        if(code){
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            var tokendata = {
            code:code,
            client_id:client_id,
            client_secret:client_secret,
            grant_type:"authorization_code",
            redirect_uri:redirect_uri
            };
             this.http.post(API_URL+"/oauth/token/", tokendata, {headers: headers}).subscribe((data) => {
               var response = data.json();
               //get the user information
               //localStorage.setItem('currentAuth', JSON.stringify(response));
               var empheaders = new Headers();
               empheaders.append('Content-Type', 'application/json');
               empheaders.append('Authorization', 'Bearer '+response.access_token);
               this.http.get(API_URL+"/user/userinfo/",{headers: empheaders}).subscribe((userdata) => {
                   localStorage.setItem('currentUser', JSON.stringify(userdata.json()));
                   localStorage.setItem('currentAuth', JSON.stringify(response));
                   //this.router.navigateByUrl('/home');
                   window.location.href = '/home';
               },(emperr)=>{
               this.router.navigateByUrl('/forbidden');
               console.log(emperr);
                });
               //this.router.navigateByUrl('/home');
            }, (err)=>{
            this.router.navigateByUrl('/forbidden');
            console.log(err);
            });
        }
        else{
            this.router.navigateByUrl('/forbidden');
        }
    }
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentAuth');
      localStorage.removeItem('currentUser');
      //this.router.navigateByUrl('/login');
    }
    token() {
      const token = JSON.parse(localStorage.getItem('currentAuth'));
      if(token)
      {
        return true;
      }else{
        return false;
      }
    }
    user() {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      if(user)
      {
        return user;
      }else{
        return false;
      }
    }
}
