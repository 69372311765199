import {
  Injectable
} from '@angular/core';
import {
  HttpClientCust
} from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class ResponsibilitiesService {

  constructor(private http: HttpClientCust) {}
  get(divisionId = null,viewType=null) {
    if (divisionId) {
      return this.http.get("/r-and-r/responsibilities/?division=" + divisionId+"&viewType="+viewType);
    } else {
      return this.http.get("/r-and-r/responsibilities/");
    }
  }
  post(data) {
    return this.http.post("/r-and-r/responsibilities/", data);
  }
  put(id, data) {
    return this.http.put("/r-and-r/responsibilities/" + id + "/", data);
  }
  delete(id) {
    return this.http.delete("/r-and-r/responsibilities/" + id + "/");
  }
  updatePositions(data,viewType) {
    return this.http.post("/r-and-r/responsibilities_position/?viewType="+viewType,data);
  }
  checkError(error) {
    return this.http.checkError(error);
  }
  getClassification(divisionId = null) {
    if (divisionId) {
      return this.http.get("/r-and-r/division_classifications/?division=" + divisionId);
    } else {
      return this.http.get("/r-and-r/division_classifications/");
    }
  }

}
