import { Component, OnInit } from '@angular/core';
import { RoleService } from '../services/role/role.service';
import { DivisionsService } from '../services/divisions/divisions.service';
import { LocationsService } from '../services/locations/locations.service';
import { EmployeeService } from '../services/employee/employee.service';
import { ApplicationSettingsService } from '../services/application-settings/application-settings.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  divisions:any;
  locations:any;
  employees:any;
  responsibilities:any;
  emp_responsibilities:any;
  div_responsibilities:any;
  employee_roles:any;
  search:any={};
  application_settting:any={};
  classification_id:number;
  constructor(private ds:DivisionsService,private ls:LocationsService,private es:EmployeeService,private rs:RoleService,private ass:ApplicationSettingsService) {
    this.search = {
      divisionId:"",
      locationdivision:"",
      employeeId:"",
      divisionResId:"",
      responsibilitie_id:""
    }
    this.ass.get().subscribe((result)=>{
      if(result[0]){
        this.application_settting = result[0];
      }else{
        this.application_settting.is_role_based_responsibility_ass = 0;
      this.application_settting.id =""
      }
      
    },(error)=>{
      this.ass.checkError(error);
    });

    this.ds.get().subscribe((result)=>{
      this.divisions = result;
  },(error)=>{
    ds.checkError(error);
  });
  this.es.getAllEmployee().subscribe((result)=>{
    this.employees = result;
},(error)=>{
  ds.checkError(error);
});

   }

  ngOnInit() {
  }

  getLocations(divisionId){
    this.search = {
      divisionId:divisionId,
      locationdivision:"",
      employeeId:"",
      divisionResId:"",
      responsibilitie_id:""
    }
    this.responsibilities = [];
    this.emp_responsibilities = [];
    this.employee_roles=[];
    this.locations = [];
    if(divisionId){
      this.ls.get(divisionId).subscribe((result)=>{
        this.locations = result;
      },(error)=>{
        this.ls.checkError(error);
      });
    }
   
}
getResponsibilities(locationDivisoinId){
  this.ls.responsibilities_location(locationDivisoinId,true).subscribe((result)=>{
      this.responsibilities=result;
    },(error)=>{
      this.ls.checkError(error);
    });
}
getList(locationDivisoinId){
  this.search = {
    divisionId:this.search.divisionId,
    locationdivision:locationDivisoinId,
    employeeId:"",
    divisionResId:"",
    responsibilitie_id:""
  }
  this.emp_responsibilities = [];
  this.employee_roles = [];
  if(locationDivisoinId){
    this.getResponsibilities(locationDivisoinId);
  }else{
    this.responsibilities = [];
  }
  
}
getResponsibility(divisionId){
  this.search = {
    divisionId:"",
    locationdivision:"",
    employeeId:"",
    divisionResId:divisionId,
    responsibilitie_id:""
  }
  this.responsibilities = [];
  this.emp_responsibilities = []
  if(divisionId){
    this.rs.getResponsibilities(divisionId).subscribe((result)=>{
      this.div_responsibilities=result;
    },(error)=>{
      this.ls.checkError(error);
    });
  }
  

}
getEmployeeResponsibility(emp_id){
  this.search = {
    divisionId:"",
    locationdivision:"",
    employeeId:emp_id,
    divisionResId:"",
    responsibilitie_id:""
  }
  this.responsibilities = [];
  this.employee_roles=[];
  if(emp_id){
    this.es.getResponsibilitiesByEmployee(emp_id).subscribe((result)=>{
      this.emp_responsibilities=result;
    },(error)=>{
      this.ls.checkError(error);
    });
  }else{
    this.emp_responsibilities = [];
  }
  
}
getEmployeeRoles(division_id,res_id){
  this.search = {
    divisionId:"",
    locationdivision:"",
    employeeId:"",
    divisionResId:division_id,
    responsibilitie_id:res_id
  }
  if(res_id){
    this.es.getEmployeeAndRoles(division_id,res_id).subscribe((result)=>{
      this.employee_roles=result;
    },(error)=>{
      this.ls.checkError(error);
    });
  }else{
    this.employee_roles = [];
  }
  

}

check_classification(classification) {
  if (this.classification_id !== classification) {
    this.classification_id = classification;
    return true;
  } else {
    return false;
  }
}

}
