import { Injectable } from '@angular/core';
import { HttpClientCust } from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {

  constructor(private http:HttpClientCust) { }

    get(){
        return this.http.get("/organization/application-setting/");
    }
    
    post(data){
      return this.http.post("/organization/application-setting/",data);
  }
  put(id, data) {
    return this.http.put("/organization/application-setting/" + id + "/", data);
  }
    checkError(error){
        return this.http.checkError(error);
    }
}
