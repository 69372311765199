import { Component, OnInit } from '@angular/core';
import { DivisionsService } from '../services/divisions/divisions.service';
import { LocationsService } from '../services/locations/locations.service';
import { EmployeeService } from '../services/employee/employee.service';
import { RoleService } from '../services/role/role.service';
import { ApplicationSettingsService } from '../services/application-settings/application-settings.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  divisions:any;
  locations:any;
  locationdivision:string="";
  employees:any=[];
  responsibilities:any;
  divisionId:string="";
  roles:any;
  response:any;
  location_divisoin_id:number;
  responsibilitie_employee_role:any=[];
  responsibilitie_employee:any=[];
  application_settting:any={};
  data:any={};
  classification_id:string="";
  constructor(private ds:DivisionsService,private ls:LocationsService,private es:EmployeeService,private rs:RoleService,private ass:ApplicationSettingsService) {

    this.ass.get().subscribe((result)=>{
      if(result[0]){
        this.application_settting = result[0];
      }else{
        this.application_settting.is_role_based_responsibility_ass = 0;
      this.application_settting.id =""
      }
      
    },(error)=>{
      this.ass.checkError(error);
    });


    this.ds.get().subscribe((result)=>{
      this.divisions = result;
  },(error)=>{
    ds.checkError(error);
  });

  
  this.rs.getRole().subscribe((result)=>{
    this.roles = result;
  },(error)=>{
    ds.checkError(error);
  });
  
  }

  getLocations(divisionId){
    this.locationdivision="";
    this.employees = [];
    this.responsibilities = [];
    this.locations=[];
    if(divisionId){
      this.ls.get(divisionId).subscribe((result)=>{
        this.locations = result;
      },(error)=>{
        this.ls.checkError(error);
      });
    }
    
}
getList(locationDivisoinId){
  this.classification_id = "";
  //this.locationdivision = null;
 
  this.responsibilitie_employee_role=[];
  this.responsibilitie_employee=[];
  this.location_divisoin_id = locationDivisoinId;
  //this.application_settting.is_role_based_responsibility_ass
  if(locationDivisoinId){
    this.getResponsibilities(locationDivisoinId);
    this.getEmployees(locationDivisoinId);
    this.rs.getEmployeeResponsibilityRole(locationDivisoinId).subscribe((result)=>{
      this.response  = result;
        if(this.application_settting.is_role_based_responsibility_ass){
          this.response.forEach((row) => {
            if(row.role){
                this.responsibilitie_employee_role[row.responsibility+"_"+row.employee+"_"+row.role]="yes";
                this.responsibilitie_employee[row.responsibility+"_"+row.employee]="yes";
            }
            else{
                this.responsibilitie_employee_role[row.responsibility+"_"+row.employee+"_"+row.role]="no";
                this.responsibilitie_employee[row.responsibility+"_"+row.employee]="no";
            }
            this.responsibilitie_employee_role[row.responsibility+"_"+row.employee+"_"+row.role+"_id"]=row.id;
            this.responsibilitie_employee[row.responsibility+"_"+row.employee+"_id"]=row.id;
        })
        }else{
          this.response.forEach((row) => {
            if(row.active){
                
                this.responsibilitie_employee[row.responsibility+"_"+row.employee]="yes";
            }
            else{
                this.responsibilitie_employee[row.responsibility+"_"+row.employee]="no";
            }
            this.responsibilitie_employee[row.responsibility+"_"+row.employee+"_id"]=row.id;
        })
        }
          
    },(error)=>{
      this.ls.checkError(error);
    });
  }else{
    this.responsibilities=[];
  }
 
}
getEmployees(locationDivisoinId){
  this.es.get(locationDivisoinId).subscribe((result)=>{
    this.employees = result;
  },(error)=>{
    this.ls.checkError(error);
  });
}
getResponsibilities(locationDivisoinId){
  this.ls.responsibilities_location(locationDivisoinId,true).subscribe((result)=>{
      this.responsibilities=result;
    },(error)=>{
      this.ls.checkError(error);
    });
}
updateRole(event,responsibility_id,employee_id,action){
  if(action=='update_role'){
    if(event.target.value){
      this.data = {responsibility:responsibility_id,employee:employee_id,role:event.target.value,division_location:this.location_divisoin_id};
    }else{
      this.data = {responsibility:responsibility_id,employee:employee_id,role:"",division_location:this.location_divisoin_id};
    }
  }
  else{
    if(event.target.checked){
      this.data = {responsibility:responsibility_id,employee:employee_id,active:true,division_location:this.location_divisoin_id};
    }
    else{
      this.data = {responsibility:responsibility_id,employee:employee_id,active:false,division_location:this.location_divisoin_id};
    }
  }
  
  // var data = {responsibility:responsibility_id,employee:employee_id,active:active,role:role_id,division_location:this.location_divisoin_id};
  if(this.responsibilitie_employee[responsibility_id+"_"+employee_id]){
    //update
    // let data = {responsibility:responsibility_id,employee:employee_id,active:active,division_location:this.location_divisoin_id};
    this.rs.updateEmployeeResponsibilityRole(this.responsibilitie_employee[responsibility_id+"_"+employee_id+"_id"],this.location_divisoin_id,this.data).subscribe((result)=>{
      this.response  = result;
      if(this.application_settting.is_role_based_responsibility_ass){
          if(this.response.role){
              this.responsibilitie_employee_role[this.response.responsibility+"_"+this.response.employee+"_"+this.response.role]="yes";
              this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee]="yes";
          }
          else{
              this.responsibilitie_employee_role[this.response.responsibility+"_"+this.response.employee+"_"+this.response.role]="no";
              this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee]="no";
          }
          this.responsibilitie_employee_role[this.response.responsibility+"_"+this.response.employee+"_"+this.response.role+"_id"]=this.response.id;
          this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee+"_id"]=this.response.id;
        }else{
          if(this.response.active){
            this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee]="yes";
        }
        else{
            this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee]="no";
        }
        this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee+"_id"]=this.response.id;
        }
      });

  }else{
    //post
    // let data = {responsibility:responsibility_id,employee:employee_id,active:active,role:event.target.value,division_location:this.location_divisoin_id};
    if(!this.data.active){
      this.data.active = false;
    }
    this.rs.createEmployeeResponsibilityRole(this.data).subscribe((result)=>{
      this.response  = result;
      if(this.application_settting.is_role_based_responsibility_ass){
      if(this.response.role){
          this.responsibilitie_employee_role[this.response.responsibility+"_"+this.response.employee+"_"+this.response.role]="yes";
          this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee]="yes";
      }
      else{
          this.responsibilitie_employee_role[this.response.responsibility+"_"+this.response.employee+"_"+this.response.role]="no";
          this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee]="no";
      }
      this.responsibilitie_employee_role[this.response.responsibility+"_"+this.response.employee+"_"+this.response.role+"_id"]=this.response.id;
      this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee+"_id"]=this.response.id;
    }else{
      if(this.response.active){
        this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee]="yes";
    }
    else{
        this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee]="no";
    }
    this.responsibilitie_employee[this.response.responsibility+"_"+this.response.employee+"_id"]=this.response.id;
    }
      });
  }
}


selectedRole(responsibility_id,employee_id,role_id){
  if(this.responsibilitie_employee_role[responsibility_id+"_"+employee_id+"_"+role_id] == "yes")
  {
      return "selectd";
  }
  return "";
}

checkResposibility(responsibilityId,employee_id){
  if(this.responsibilitie_employee[responsibilityId+"_"+employee_id] == "yes")
  {
      return "checked";
  }
  return "";
}

check_classification(classification) {
  if (this.classification_id !== classification) {
    this.classification_id = classification;
    return true;
  } else {
    return false;
  }
}
  ngOnInit() {
  }

}
