import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth/auth.service';
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(private auth:AuthService) {
      var href = window.location.href;
      console.log(href);
      var extractedcode = href.split('=');
      var ourcode = extractedcode[1];
      auth.getAccessToken(ourcode);
  }
  ngOnInit() {
  }

}
