import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { CallbackComponent } from './callback/callback.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { TestComponent } from './test/test.component';
import { ResponsibilitiesComponent } from './responsibilities/responsibilities.component';
import { ClassificationsComponent } from './classifications/classifications.component';
import { DivisionClassificationsComponent } from './division-classifications/division-classifications.component';
import { LocationsComponent } from './locations/locations.component';
import { EmployeeComponent } from './employee/employee.component';
import { OrgScopeComponent } from './org-scope/org-scope.component';
import { DivisionComponent } from './division/division.component';
import { SearchComponent } from './search/search.component';
import { AuditComponent } from './audit/audit.component';
import { ApplicationSettingsComponent } from './application-settings/application-settings.component';
import { MyResponsibilityComponent } from './my-responsibility/my-responsibility.component';
import { CorrelationComponent } from './correlation/correlation.component';
const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "callback",
    component: CallbackComponent
  },
  {
    path: "forbidden",
    component: ForbiddenComponent
  },
  {
    path: "home",
    component: HomeComponent
  },
  {
    path: "responsibilities",
    component: ResponsibilitiesComponent
  },
  {
    path: "test",
    component: TestComponent
  },
  {
    path: "classifications",
    component: ClassificationsComponent
  },
  {
    path: "locations",
    component: LocationsComponent
  },
  {
    path:"employee",
    component:EmployeeComponent
  },
  {
    path:'scope',
    component:OrgScopeComponent
  },
  {
    path:'division',
    component:DivisionComponent
  },
  {
    path:'search',
    component:SearchComponent
  },
  {
    path:'application-settings',
    component:ApplicationSettingsComponent
  },
  {
    path:'audit',
    component:AuditComponent
  },
  {
    path:'division-function',
    component:DivisionClassificationsComponent
  },
  {
    path:'my-responsibilities',
    component:MyResponsibilityComponent
  },
  {
    path:'correlation',
    component:CorrelationComponent
    //component:TestComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
