import { Injectable } from '@angular/core';
import { HttpClientCust } from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class OrgScopeService {

  constructor(private http:HttpClientCust) { }
  get(){
    return this.http.get("/r-and-r/scope/")
  }
  post(data){
    return this.http.post("/r-and-r/scope/",data)
  }
  put(id,data){
    return this.http.put("/r-and-r/scope/"+id+"/",data);
  }
  checkError(error){
    return this.http.checkError(error);
}
delete(id){
  return this.http.delete("/r-and-r/scope/"+id+"/");
}
}
