import {
  Injectable
} from '@angular/core';
import {
  HttpClientCust
} from '../http/http-client-cust';
@Injectable({
  providedIn: 'root'
})
export class DivisionClassificationsService {

  constructor(private http: HttpClientCust) {}
  get(divisionId = null) {
    if (divisionId) {
      return this.http.get("/r-and-r/division_classifications/?division=" + divisionId);
    } else {
      return this.http.get("/r-and-r/division_classifications/");
    }
  }
  new(data) {
    return this.http.post("/r-and-r/division_classifications/", data);
  }
  put(id,data){
    return this.http.put("/r-and-r/division_classifications/"+id+"/",data);
  }
delete(id){
  return this.http.delete("/r-and-r/division_classifications/"+id+"/");
}
  checkError(error) {
    return this.http.checkError(error);
  }
}
