import {
  Component,
  OnInit
} from '@angular/core';
import {
  ResponsibilitiesService
} from '../services/responsibilities/responsibilities.service';
import {
  DivisionsService
} from '../services/divisions/divisions.service';
@Component({
  selector: 'app-correlation',
  templateUrl: './correlation.component.html',
  styleUrls: ['./correlation.component.css']
})
export class CorrelationComponent implements OnInit {
  currentdivision: any;
  datachanged:any;
  divisions: any;
  divisionId = 'null';
  responsibilities: any;
  responsibilities1: any;
  cpsv_classifications = [];
  dfv_classifications = [];
  cpsv_flag = 0;
  dfv_flag = 0;
  classification_id = -1;
  division_function: any;
  division_function_name: any;
  constructor(private ds: DivisionsService,private rs:  ResponsibilitiesService) {
    //this.getResposiblities();
  }
  ngOnInit() {
    this.ds.get().subscribe((result) => {
      this.divisions = result;
      if(result[0]){
        this.getReponsiblity(result[0].id);
      }
    }, (error) => {
      this.ds.checkError(error);
    });
  }
  getReponsiblity(divisionId) {
    if(this.divisionId === 'null'){
      return;
    }
    this.rs.get(divisionId,'cpsv').subscribe((result) => {
      this.responsibilities = result;
        this.cpsv();
    },(error)=>{
      this.rs.checkError(error);
    });
    this.rs.get(divisionId,'dfv').subscribe((result) => {
      this.responsibilities1 = result;
      this.dfv();
    },(error)=>{
      this.rs.checkError(error);
    });
    this.currentdivision = divisionId;
  }
  cpsv() {
    var i=-1;
    var j=0;
    this.classification_id=0;
    this.cpsv_classifications=new Array();
    this.responsibilities.forEach(element => {
      if(this.classification_id != element.classification){
          this.classification_id=element.classification;
          this.cpsv_classifications[++i]=new Array();
          this.cpsv_classifications[i]['name']=element.classification_data.name;
          this.cpsv_classifications[i]['id']=element.classification;
          this.cpsv_classifications[i]['responsibilities']=new Array();
          this.cpsv_classifications[i]['responsibilities'].push({id:element.id,name:element.name,description:element.description,position:element.position});
      }
      else{
          this.cpsv_classifications[i]['responsibilities'].push({id:element.id,name:element.name,description:element.description,position:element.position});
      }
    });
    this.cpsv_flag = 1;
    this.datachangedFnc();
}
  dfv() {
      var i=-1;
      var j=0;
      this.classification_id=0;
      this.dfv_classifications=new Array();
      this.responsibilities1.forEach(element => {
        if(this.classification_id != element.division_function){
            this.classification_id=element.division_function;
            this.dfv_classifications[++i]=new Array();
            if(element.function_data){
              this.dfv_classifications[i]['name']=element.function_data.name;
            }
            else{
              this.dfv_classifications[i]['name']='Unassigned';
            }
            this.dfv_classifications[i]['id']=element.division_function;
            this.dfv_classifications[i]['responsibilities']=new Array();
            this.dfv_classifications[i]['responsibilities'].push({id:element.id,name:element.name,description:element.description,position:element.function_position});
        }
        else{
            this.dfv_classifications[i]['responsibilities'].push({id:element.id,name:element.name,description:element.description,position:element.function_position});
        }
      });
      this.dfv_flag = 1;
      this.datachangedFnc();
      //console.log(this.dfv_classifications);
  }
  datachangedFnc() {
    if(this.dfv_flag === 1 && this.cpsv_flag === 1){
      if(this.datachanged){
        this.datachanged = false;
      } else {
        this.datachanged = true;
      }
      this.cpsv_flag = 0;
      this.dfv_flag = 0;
    }
  }
}
